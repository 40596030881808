<template>
  <div class="jhyxpj">
    <div class="title">运行评价</div>
    <div class="content">
      <div style="display: flex">
        <div class="box1-item">
          <div class="biaotix">C-1#聚合釜</div>
          <ul>
            <li class="licontent">
              <div class="li_item"></div>
              <div class="li_iteml">自控率</div>
              <div class="li_iteml">稳定性</div>
              <div class="li_iteml">经济性</div>
              <div class="li_iteml">综合评价</div>
            </li>
            <li class="licontent">
              <div class="li_item">班运行</div>
              <div
                class="li_value "
                @click="
                  toCompon(2, 'ANEV101', 'DEVICE1V', 'ANEV101_FYF', '班总自控率指标')
                "
                @dblclick="
                  Cclick(infoList.DEVICE1V.ANEV101, 'ANEV101', 'DEVICE1V', 'ANEV101_FYF')
                "
              >
             {{ infoList.DEVICE1V.ANEV101 }}
              </div>
              <div
                class="li_value "
                @click="
                  toCompon(2, 'ANEV102', 'DEVICE1V', 'ANEV102_FYF', '班稳定性指标')
                "
                @dblclick="
                  Cclick(infoList.DEVICE1V.ANEV102, 'ANEV102', 'DEVICE1V', 'ANEV102_FYF')
                "
                >
             {{ infoList.DEVICE1V.ANEV102 }}
              </div>
              <div
                class="li_value "
                @click="
                  toCompon(2, 'ANEV103', 'DEVICE1V', 'ANEV103_FYF', '班经济性指标')
                "
                @dblclick="
                  Cclick(infoList.DEVICE1V.ANEV103, 'ANEV103', 'DEVICE1V', 'ANEV103_FYF')
                "
              >
             {{ infoList.DEVICE1V.ANEV103 }}
              </div>
              <div
                class="li_value "
                @click="
                  toCompon(2, 'ANEV104', 'DEVICE1V', 'ANEV104_FYF', '班综合评价指标')
                "
                @dblclick="
                  Cclick(infoList.DEVICE1V.ANEV104, 'ANEV104', 'DEVICE1V', 'ANEV104_FYF')
                "
              >
             {{ infoList.DEVICE1V.ANEV104 }}
              </div>
            </li>
            <li class="licontent">
              <div class="li_item">日运行</div>
              <div
                class="li_value "
                @click="
                  toCompon(2, 'ANEV201', 'DEVICE1V', 'ANEV201_FYF', '日总自控率指标')
                "
                @dblclick="
                  Cclick(infoList.DEVICE1V.ANEV201, 'ANEV201', 'DEVICE1V', 'ANEV201_FYF')
                "
              >
             {{ infoList.DEVICE1V.ANEV201 }}
              </div>
              <div
                class="li_value "
                @click="
                  toCompon(2, 'ANEV202', 'DEVICE1V', 'ANEV202_FYF', '日稳定性指标')
                "
                @dblclick="
                  Cclick(infoList.DEVICE1V.ANEV202, 'ANEV202', 'DEVICE1V', 'ANEV202_FYF')
                "
                >
             {{ infoList.DEVICE1V.ANEV202 }}
              </div>
              <div
                class="li_value "
                @click="
                  toCompon(2, 'ANEV203', 'DEVICE1V', 'ANEV203_FYF', '日经济性指标')
                "
                @dblclick="
                  Cclick(infoList.DEVICE1V.ANEV203, 'ANEV203', 'DEVICE1V', 'ANEV203_FYF')
                "
              >
             {{ infoList.DEVICE1V.ANEV203 }}
              </div>
              <div
                class="li_value "
                @click="
                  toCompon(2, 'ANEV204', 'DEVICE1V', 'ANEV204_FYF', '日综合评价指标')
                "
                @dblclick="
                  Cclick(infoList.DEVICE1V.ANEV204, 'ANEV204', 'DEVICE1V', 'ANEV204_FYF')
                "
              >
             {{ infoList.DEVICE1V.ANEV204 }}
              </div>
            </li>
            <li class="licontent">
              <div class="li_item">月运行</div>
              <div
                class="li_value "
                @click="
                  toCompon(2, 'ANEV301', 'DEVICE1V', 'ANEV301_FYF', '月总自控率指标')
                "
                @dblclick="
                  Cclick(infoList.DEVICE1V.ANEV301, 'ANEV301', 'DEVICE1V', 'ANEV301_FYF')
                "
              >
             {{ infoList.DEVICE1V.ANEV301 }}
              </div>
              <div
                class="li_value "
                @click="
                  toCompon(2, 'ANEV302', 'DEVICE1V', 'ANEV302_FYF', '月稳定性指标')
                "
                @dblclick="
                  Cclick(infoList.DEVICE1V.ANEV302, 'ANEV302', 'DEVICE1V', 'ANEV302_FYF')
                "
                >
             {{ infoList.DEVICE1V.ANEV302 }}
              </div>
              <div
                class="li_value "
                @click="
                  toCompon(2, 'ANEV303', 'DEVICE1V', 'ANEV303_FYF', '月经济性指标')
                "
                @dblclick="
                  Cclick(infoList.DEVICE1V.ANEV303, 'ANEV303', 'DEVICE1V', 'ANEV303_FYF')
                "
              >
             {{ infoList.DEVICE1V.ANEV303 }}
              </div>
              <div
                class="li_value "
                @click="
                  toCompon(2, 'ANEV304', 'DEVICE1V', 'ANEV304_FYF', '月综合评价指标')
                "
                @dblclick="
                  Cclick(infoList.DEVICE1V.ANEV304, 'ANEV304', 'DEVICE1V', 'ANEV304_FYF')
                "
              >
             {{ infoList.DEVICE1V.ANEV304 }}
              </div>
            </li>
            <li class="licontent">
              <div class="li_item">自定义运行</div>
              <div
                class="li_value "
                @click="
                  toCompon(2, 'ANEV401', 'DEVICE1V', 'ANEV401_FYF', '自定义总自控率指标')
                "
                @dblclick="
                  Cclick(infoList.DEVICE1V.ANEV401, 'ANEV401', 'DEVICE1V', 'ANEV401_FYF')
                "
              >
             {{ infoList.DEVICE1V.ANEV401 }}
              </div>
              <div
                class="li_value "
                @click="
                  toCompon(2, 'ANEV402', 'DEVICE1V', 'ANEV402_FYF', '自定义稳定性指标')
                "
                @dblclick="
                  Cclick(infoList.DEVICE1V.ANEV402, 'ANEV402', 'DEVICE1V', 'ANEV402_FYF')
                "
                >
             {{ infoList.DEVICE1V.ANEV402 }}
              </div>
              <div
                class="li_value "
                @click="
                  toCompon(2, 'ANEV403', 'DEVICE1V', 'ANEV403_FYF', '自定义经济性指标')
                "
                @dblclick="
                  Cclick(infoList.DEVICE1V.ANEV403, 'ANEV403', 'DEVICE1V', 'ANEV403_FYF')
                "
              >
             {{ infoList.DEVICE1V.ANEV403 }}
              </div>
              <div
                class="li_value "
                @click="
                  toCompon(2, 'ANEV404', 'DEVICE1V', 'ANEV404_FYF', '自定义综合评价指标')
                "
                @dblclick="
                  Cclick(infoList.DEVICE1V.ANEV404, 'ANEV404', 'DEVICE1V', 'ANEV404_FYF')
                "
              >
             {{ infoList.DEVICE1V.ANEV404 }}
              </div>
            </li>
            <li class="liyxpj">
              <div class="li_item">CEI</div>
              <div
                class="lipjjg"
                @click="
                  toCompon(2, 'TE04', 'DEVICE1S', 'TE04_FYF', '1#聚合釜温度')
                "
                @dblclick="
                  Cclick(infoList.DEVICE1S.TE04, 'TE04', 'DEVICE1S', 'TE04_FYF')
                "
              >
         /
              </div>
              <div
                class="lipjjg"
                @click="
                  toCompon(2, 'TE04', 'DEVICE1S', 'TE04_FYF', '1#聚合釜温度')
                "
                @dblclick="
                  Cclick(infoList.DEVICE1S.TE04, 'TE04', 'DEVICE1S', 'TE04_FYF')
                "
              >
           /
              </div>
            
             
            </li>
          </ul>
        </div>
        <div class="box1-item">
          <div class="biaotix">C-2#聚合釜</div>
          <ul>
            <li class="licontent">
              <div class="li_item"></div>
              <div class="li_iteml">自控率</div>
              <div class="li_iteml">稳定性</div>
              <div class="li_iteml">经济性</div>
              <div class="li_iteml">综合评价</div>
            </li>
            <li class="licontent">
              <div class="li_item">班运行</div>
              <div
                class="li_value "
                @click="
                  toCompon(2, 'ANEV101', 'DEVICE2V', 'ANEV101_FYF', '班总自控率指标')
                "
                @dblclick="
                  Cclick(infoList.DEVICE2V.ANEV101, 'ANEV101', 'DEVICE2V', 'ANEV101_FYF')
                "
              >
             {{ infoList.DEVICE2V.ANEV101 }}
              </div>
              <div
                class="li_value "
                @click="
                  toCompon(2, 'ANEV102', 'DEVICE2V', 'ANEV102_FYF', '班稳定性指标')
                "
                @dblclick="
                  Cclick(infoList.DEVICE2V.ANEV102, 'ANEV102', 'DEVICE2V', 'ANEV102_FYF')
                "
                >
             {{ infoList.DEVICE2V.ANEV102 }}
              </div>
              <div
                class="li_value "
                @click="
                  toCompon(2, 'ANEV103', 'DEVICE2V', 'ANEV103_FYF', '班经济性指标')
                "
                @dblclick="
                  Cclick(infoList.DEVICE2V.ANEV103, 'ANEV103', 'DEVICE2V', 'ANEV103_FYF')
                "
              >
             {{ infoList.DEVICE2V.ANEV103 }}
              </div>
              <div
                class="li_value "
                @click="
                  toCompon(2, 'ANEV104', 'DEVICE2V', 'ANEV104_FYF', '班综合评价指标')
                "
                @dblclick="
                  Cclick(infoList.DEVICE2V.ANEV104, 'ANEV104', 'DEVICE2V', 'ANEV104_FYF')
                "
              >
             {{ infoList.DEVICE2V.ANEV104 }}
              </div>
            </li>
            <li class="licontent">
              <div class="li_item">日运行</div>
              <div
                class="li_value "
                @click="
                  toCompon(2, 'ANEV201', 'DEVICE2V', 'ANEV201_FYF', '日总自控率指标')
                "
                @dblclick="
                  Cclick(infoList.DEVICE2V.ANEV201, 'ANEV201', 'DEVICE2V', 'ANEV201_FYF')
                "
              >
             {{ infoList.DEVICE2V.ANEV201 }}
              </div>
              <div
                class="li_value "
                @click="
                  toCompon(2, 'ANEV202', 'DEVICE2V', 'ANEV202_FYF', '日稳定性指标')
                "
                @dblclick="
                  Cclick(infoList.DEVICE2V.ANEV202, 'ANEV202', 'DEVICE2V', 'ANEV202_FYF')
                "
                >
             {{ infoList.DEVICE2V.ANEV202 }}
              </div>
              <div
                class="li_value "
                @click="
                  toCompon(2, 'ANEV203', 'DEVICE2V', 'ANEV203_FYF', '日经济性指标')
                "
                @dblclick="
                  Cclick(infoList.DEVICE2V.ANEV203, 'ANEV203', 'DEVICE2V', 'ANEV203_FYF')
                "
              >
             {{ infoList.DEVICE2V.ANEV203 }}
              </div>
              <div
                class="li_value "
                @click="
                  toCompon(2, 'ANEV204', 'DEVICE2V', 'ANEV204_FYF', '日综合评价指标')
                "
                @dblclick="
                  Cclick(infoList.DEVICE2V.ANEV204, 'ANEV204', 'DEVICE2V', 'ANEV204_FYF')
                "
              >
             {{ infoList.DEVICE2V.ANEV204 }}
              </div>
            </li>
            <li class="licontent">
              <div class="li_item">月运行</div>
              <div
                class="li_value "
                @click="
                  toCompon(2, 'ANEV301', 'DEVICE2V', 'ANEV301_FYF', '月总自控率指标')
                "
                @dblclick="
                  Cclick(infoList.DEVICE2V.ANEV301, 'ANEV301', 'DEVICE2V', 'ANEV301_FYF')
                "
              >
             {{ infoList.DEVICE2V.ANEV301 }}
              </div>
              <div
                class="li_value "
                @click="
                  toCompon(2, 'ANEV302', 'DEVICE2V', 'ANEV302_FYF', '月稳定性指标')
                "
                @dblclick="
                  Cclick(infoList.DEVICE2V.ANEV302, 'ANEV302', 'DEVICE2V', 'ANEV302_FYF')
                "
                >
             {{ infoList.DEVICE2V.ANEV302 }}
              </div>
              <div
                class="li_value "
                @click="
                  toCompon(2, 'ANEV303', 'DEVICE2V', 'ANEV303_FYF', '月经济性指标')
                "
                @dblclick="
                  Cclick(infoList.DEVICE2V.ANEV303, 'ANEV303', 'DEVICE2V', 'ANEV303_FYF')
                "
              >
             {{ infoList.DEVICE1V.ANEV303 }}
              </div>
              <div
                class="li_value "
                @click="
                  toCompon(2, 'ANEV304', 'DEVICE2V', 'ANEV304_FYF', '月综合评价指标')
                "
                @dblclick="
                  Cclick(infoList.DEVICE2V.ANEV304, 'ANEV304', 'DEVICE2V', 'ANEV304_FYF')
                "
              >
             {{ infoList.DEVICE2V.ANEV304 }}
              </div>
            </li>
            <li class="licontent">
              <div class="li_item">自定义运行</div>
              <div
                class="li_value "
                @click="
                  toCompon(2, 'ANEV401', 'DEVICE2V', 'ANEV401_FYF', '自定义总自控率指标')
                "
                @dblclick="
                  Cclick(infoList.DEVICE2V.ANEV401, 'ANEV401', 'DEVICE2V', 'ANEV401_FYF')
                "
              >
             {{ infoList.DEVICE2V.ANEV401 }}
              </div>
              <div
                class="li_value "
                @click="
                  toCompon(2, 'ANEV402', 'DEVICE2V', 'ANEV402_FYF', '自定义稳定性指标')
                "
                @dblclick="
                  Cclick(infoList.DEVICE2V.ANEV402, 'ANEV402', 'DEVICE2V', 'ANEV402_FYF')
                "
                >
             {{ infoList.DEVICE2V.ANEV402 }}
              </div>
              <div
                class="li_value "
                @click="
                  toCompon(2, 'ANEV403', 'DEVICE2V', 'ANEV403_FYF', '自定义经济性指标')
                "
                @dblclick="
                  Cclick(infoList.DEVICE2V.ANEV403, 'ANEV403', 'DEVICE2V', 'ANEV403_FYF')
                "
              >
             {{ infoList.DEVICE2V.ANEV403 }}
              </div>
              <div
                class="li_value "
                @click="
                  toCompon(2, 'ANEV404', 'DEVICE2V', 'ANEV404_FYF', '自定义综合评价指标')
                "
                @dblclick="
                  Cclick(infoList.DEVICE2V.ANEV404, 'ANEV404', 'DEVICE2V', 'ANEV404_FYF')
                "
              >
             {{ infoList.DEVICE2V.ANEV404 }}
              </div>
            </li>
            <li class="liyxpj">
              <div class="li_item">CEI</div>
              <div
                class="lipjjg"
              >
             /
              </div>
              <div
                class="lipjjg"
            
              >
           /
              </div>
            
             
            </li>
          </ul>
        </div>
        <div class="box1-item">
          <div class="biaotix">C-3#聚合釜</div>
          <ul>
            <li class="licontent">
              <div class="li_item"></div>
              <div class="li_iteml">自控率</div>
              <div class="li_iteml">稳定性</div>
              <div class="li_iteml">经济性</div>
              <div class="li_iteml">综合评价</div>
            </li>
            <li class="licontent">
              <div class="li_item">班运行</div>
              <div
                class="li_value "
                @click="
                  toCompon(2, 'ANEV101', 'DEVICE3V', 'ANEV101_FYF', '班总自控率指标')
                "
                @dblclick="
                  Cclick(infoList.DEVICE3V.ANEV101, 'ANEV101', 'DEVICE3V', 'ANEV101_FYF')
                "
              >
             {{ infoList.DEVICE3V.ANEV101 }}
              </div>
              <div
                class="li_value "
                @click="
                  toCompon(2, 'ANEV102', 'DEVICE3V', 'ANEV102_FYF', '班稳定性指标')
                "
                @dblclick="
                  Cclick(infoList.DEVICE3V.ANEV102, 'ANEV102', 'DEVICE3V', 'ANEV102_FYF')
                "
                >
             {{ infoList.DEVICE3V.ANEV102 }}
              </div>
              <div
                class="li_value "
                @click="
                  toCompon(2, 'ANEV103', 'DEVICE3V', 'ANEV103_FYF', '班经济性指标')
                "
                @dblclick="
                  Cclick(infoList.DEVICE3V.ANEV103, 'ANEV103', 'DEVICE3V', 'ANEV103_FYF')
                "
              >
             {{ infoList.DEVICE3V.ANEV103 }}
              </div>
              <div
                class="li_value "
                @click="
                  toCompon(2, 'ANEV104', 'DEVICE3V', 'ANEV104_FYF', '班综合评价指标')
                "
                @dblclick="
                  Cclick(infoList.DEVICE3V.ANEV104, 'ANEV104', 'DEVICE3V', 'ANEV104_FYF')
                "
              >
             {{ infoList.DEVICE3V.ANEV104 }}
              </div>
            </li>
            <li class="licontent">
              <div class="li_item">日运行</div>
              <div
                class="li_value "
                @click="
                  toCompon(2, 'ANEV201', 'DEVICE3V', 'ANEV201_FYF', '日总自控率指标')
                "
                @dblclick="
                  Cclick(infoList.DEVICE3V.ANEV201, 'ANEV201', 'DEVICE3V', 'ANEV201_FYF')
                "
              >
             {{ infoList.DEVICE3V.ANEV201 }}
              </div>
              <div
                class="li_value "
                @click="
                  toCompon(2, 'ANEV202', 'DEVICE3V', 'ANEV202_FYF', '日稳定性指标')
                "
                @dblclick="
                  Cclick(infoList.DEVICE3V.ANEV202, 'ANEV202', 'DEVICE3V', 'ANEV202_FYF')
                "
                >
             {{ infoList.DEVICE3V.ANEV202 }}
              </div>
              <div
                class="li_value "
                @click="
                  toCompon(2, 'ANEV203', 'DEVICE3V', 'ANEV203_FYF', '日经济性指标')
                "
                @dblclick="
                  Cclick(infoList.DEVICE3V.ANEV203, 'ANEV203', 'DEVICE3V', 'ANEV203_FYF')
                "
              >
             {{ infoList.DEVICE3V.ANEV203 }}
              </div>
              <div
                class="li_value "
                @click="
                  toCompon(2, 'ANEV204', 'DEVICE3V', 'ANEV204_FYF', '日综合评价指标')
                "
                @dblclick="
                  Cclick(infoList.DEVICE3V.ANEV204, 'ANEV204', 'DEVICE3V', 'ANEV204_FYF')
                "
              >
             {{ infoList.DEVICE3V.ANEV204 }}
              </div>
            </li>
            <li class="licontent">
              <div class="li_item">月运行</div>
              <div
                class="li_value "
                @click="
                  toCompon(2, 'ANEV301', 'DEVICE3V', 'ANEV301_FYF', '月总自控率指标')
                "
                @dblclick="
                  Cclick(infoList.DEVICE3V.ANEV301, 'ANEV301', 'DEVICE3V', 'ANEV301_FYF')
                "
              >
             {{ infoList.DEVICE3V.ANEV301 }}
              </div>
              <div
                class="li_value "
                @click="
                  toCompon(2, 'ANEV302', 'DEVICE3V', 'ANEV302_FYF', '月稳定性指标')
                "
                @dblclick="
                  Cclick(infoList.DEVICE3V.ANEV302, 'ANEV302', 'DEVICE3V', 'ANEV302_FYF')
                "
                >
             {{ infoList.DEVICE3V.ANEV302 }}
              </div>
              <div
                class="li_value "
                @click="
                  toCompon(2, 'ANEV303', 'DEVICE3V', 'ANEV303_FYF', '月经济性指标')
                "
                @dblclick="
                  Cclick(infoList.DEVICE3V.ANEV303, 'ANEV303', 'DEVICE3V', 'ANEV303_FYF')
                "
              >
             {{ infoList.DEVICE3V.ANEV303 }}
              </div>
              <div
                class="li_value "
                @click="
                  toCompon(2, 'ANEV304', 'DEVICE3V', 'ANEV304_FYF', '月综合评价指标')
                "
                @dblclick="
                  Cclick(infoList.DEVICE3V.ANEV304, 'ANEV304', 'DEVICE3V', 'ANEV304_FYF')
                "
              >
             {{ infoList.DEVICE3V.ANEV304 }}
              </div>
            </li>
            <li class="licontent">
              <div class="li_item">自定义运行</div>
              <div
                class="li_value "
                @click="
                  toCompon(2, 'ANEV401', 'DEVICE3V', 'ANEV401_FYF', '自定义总自控率指标')
                "
                @dblclick="
                  Cclick(infoList.DEVICE3V.ANEV401, 'ANEV401', 'DEVICE3V', 'ANEV401_FYF')
                "
              >
             {{ infoList.DEVICE3V.ANEV401 }}
              </div>
              <div
                class="li_value "
                @click="
                  toCompon(2, 'ANEV402', 'DEVICE3V', 'ANEV402_FYF', '自定义稳定性指标')
                "
                @dblclick="
                  Cclick(infoList.DEVICE3V.ANEV402, 'ANEV402', 'DEVICE3V', 'ANEV402_FYF')
                "
                >
             {{ infoList.DEVICE3V.ANEV402 }}
              </div>
              <div
                class="li_value "
                @click="
                  toCompon(2, 'ANEV403', 'DEVICE3V', 'ANEV403_FYF', '自定义经济性指标')
                "
                @dblclick="
                  Cclick(infoList.DEVICE3V.ANEV403, 'ANEV403', 'DEVICE3V', 'ANEV403_FYF')
                "
              >
             {{ infoList.DEVICE3V.ANEV403 }}
              </div>
              <div
                class="li_value "
                @click="
                  toCompon(2, 'ANEV404', 'DEVICE2V', 'ANEV404_FYF', '自定义综合评价指标')
                "
                @dblclick="
                  Cclick(infoList.DEVICE2V.ANEV404, 'ANEV404', 'DEVICE2V', 'ANEV404_FYF')
                "
              >
             {{ infoList.DEVICE2V.ANEV404 }}
              </div>
            </li>
            <li class="liyxpj">
              <div class="li_item">CEI</div>
              <div
                class="lipjjg"
              >
             /
              </div>
              <div
                class="lipjjg"
            
              >
           /
              </div>
            
             
            </li>
          </ul>
        </div>
      </div>
      <div style="display: flex">
        <div class="box1-item">
          <div class="biaotix">C-4#聚合釜</div>
          <ul>
            <li class="licontent">
              <div class="li_item"></div>
              <div class="li_iteml">自控率</div>
              <div class="li_iteml">稳定性</div>
              <div class="li_iteml">经济性</div>
              <div class="li_iteml">综合评价</div>
            </li>
            <li class="licontent">
              <div class="li_item">班运行</div>
              <div
                class="li_value "
                @click="
                  toCompon(2, 'ANEV101', 'DEVICE4V', 'ANEV101_FYF', '班总自控率指标')
                "
                @dblclick="
                  Cclick(infoList.DEVICE4V.ANEV101, 'ANEV101', 'DEVICE4V', 'ANEV101_FYF')
                "
              >
             {{ infoList.DEVICE4V.ANEV101 }}
              </div>
              <div
                class="li_value "
                @click="
                  toCompon(2, 'ANEV102', 'DEVICE4V', 'ANEV102_FYF', '班稳定性指标')
                "
                @dblclick="
                  Cclick(infoList.DEVICE4V.ANEV102, 'ANEV102', 'DEVICE4V', 'ANEV102_FYF')
                "
                >
             {{ infoList.DEVICE4V.ANEV102 }}
              </div>
              <div
                class="li_value "
                @click="
                  toCompon(2, 'ANEV103', 'DEVICE4V', 'ANEV103_FYF', '班经济性指标')
                "
                @dblclick="
                  Cclick(infoList.DEVICE4V.ANEV103, 'ANEV103', 'DEVICE4V', 'ANEV103_FYF')
                "
              >
             {{ infoList.DEVICE4V.ANEV103 }}
              </div>
              <div
                class="li_value "
                @click="
                  toCompon(2, 'ANEV104', 'DEVICE4V', 'ANEV104_FYF', '班综合评价指标')
                "
                @dblclick="
                  Cclick(infoList.DEVICE4V.ANEV104, 'ANEV104', 'DEVICE4V', 'ANEV104_FYF')
                "
              >
             {{ infoList.DEVICE4V.ANEV104 }}
              </div>
            </li>
            <li class="licontent">
              <div class="li_item">日运行</div>
              <div
                class="li_value "
                @click="
                  toCompon(2, 'ANEV201', 'DEVICE4V', 'ANEV201_FYF', '日总自控率指标')
                "
                @dblclick="
                  Cclick(infoList.DEVICE4V.ANEV201, 'ANEV201', 'DEVICE4V', 'ANEV201_FYF')
                "
              >
             {{ infoList.DEVICE4V.ANEV201 }}
              </div>
              <div
                class="li_value "
                @click="
                  toCompon(2, 'ANEV202', 'DEVICE4V', 'ANEV202_FYF', '日稳定性指标')
                "
                @dblclick="
                  Cclick(infoList.DEVICE4V.ANEV202, 'ANEV202', 'DEVICE4V', 'ANEV202_FYF')
                "
                >
             {{ infoList.DEVICE4V.ANEV202 }}
              </div>
              <div
                class="li_value "
                @click="
                  toCompon(2, 'ANEV203', 'DEVICE4V', 'ANEV203_FYF', '日经济性指标')
                "
                @dblclick="
                  Cclick(infoList.DEVICE4V.ANEV203, 'ANEV203', 'DEVICE4V', 'ANEV203_FYF')
                "
              >
             {{ infoList.DEVICE4V.ANEV203 }}
              </div>
              <div
                class="li_value "
                @click="
                  toCompon(2, 'ANEV204', 'DEVICE4V', 'ANEV204_FYF', '日综合评价指标')
                "
                @dblclick="
                  Cclick(infoList.DEVICE4V.ANEV204, 'ANEV204', 'DEVICE4V', 'ANEV204_FYF')
                "
              >
             {{ infoList.DEVICE4V.ANEV204 }}
              </div>
            </li>
            <li class="licontent">
              <div class="li_item">月运行</div>
              <div
                class="li_value "
                @click="
                  toCompon(2, 'ANEV301', 'DEVICE4V', 'ANEV301_FYF', '月总自控率指标')
                "
                @dblclick="
                  Cclick(infoList.DEVICE4V.ANEV301, 'ANEV301', 'DEVICE4V', 'ANEV301_FYF')
                "
              >
             {{ infoList.DEVICE4V.ANEV301 }}
              </div>
              <div
                class="li_value "
                @click="
                  toCompon(2, 'ANEV302', 'DEVICE4V', 'ANEV302_FYF', '月稳定性指标')
                "
                @dblclick="
                  Cclick(infoList.DEVICE4V.ANEV302, 'ANEV302', 'DEVICE4V', 'ANEV302_FYF')
                "
                >
             {{ infoList.DEVICE4V.ANEV302 }}
              </div>
              <div
                class="li_value "
                @click="
                  toCompon(2, 'ANEV303', 'DEVICE4V', 'ANEV303_FYF', '月经济性指标')
                "
                @dblclick="
                  Cclick(infoList.DEVICE4V.ANEV303, 'ANEV303', 'DEVICE4V', 'ANEV303_FYF')
                "
              >
             {{ infoList.DEVICE4V.ANEV303 }}
              </div>
              <div
                class="li_value "
                @click="
                  toCompon(2, 'ANEV304', 'DEVICE4V', 'ANEV304_FYF', '月综合评价指标')
                "
                @dblclick="
                  Cclick(infoList.DEVICE4V.ANEV304, 'ANEV304', 'DEVICE4V', 'ANEV304_FYF')
                "
              >
             {{ infoList.DEVICE4V.ANEV304 }}
              </div>
            </li>
            <li class="licontent">
              <div class="li_item">自定义运行</div>
              <div
                class="li_value "
                @click="
                  toCompon(2, 'ANEV401', 'DEVICE4V', 'ANEV401_FYF', '自定义总自控率指标')
                "
                @dblclick="
                  Cclick(infoList.DEVICE4V.ANEV401, 'ANEV401', 'DEVICE4V', 'ANEV401_FYF')
                "
              >
             {{ infoList.DEVICE4V.ANEV401 }}
              </div>
              <div
                class="li_value "
                @click="
                  toCompon(2, 'ANEV402', 'DEVICE4V', 'ANEV402_FYF', '自定义稳定性指标')
                "
                @dblclick="
                  Cclick(infoList.DEVICE4V.ANEV402, 'ANEV402', 'DEVICE4V', 'ANEV402_FYF')
                "
                >
             {{ infoList.DEVICE4V.ANEV402 }}
              </div>
              <div
                class="li_value "
                @click="
                  toCompon(2, 'ANEV403', 'DEVICE4V', 'ANEV403_FYF', '自定义经济性指标')
                "
                @dblclick="
                  Cclick(infoList.DEVICE4V.ANEV403, 'ANEV403', 'DEVICE1V', 'ANEV403_FYF')
                "
              >
             {{ infoList.DEVICE4V.ANEV403 }}
              </div>
              <div
                class="li_value "
                @click="
                  toCompon(2, 'ANEV404', 'DEVICE4V', 'ANEV404_FYF', '自定义综合评价指标')
                "
                @dblclick="
                  Cclick(infoList.DEVICE4V.ANEV404, 'ANEV404', 'DEVICE4V', 'ANEV404_FYF')
                "
              >
             {{ infoList.DEVICE4V.ANEV404 }}
              </div>
            </li>
            <li class="liyxpj">
              <div class="li_item">CEI</div>
              <div
                class="lipjjg"              
              >
             /
              </div>
              <div
                class="lipjjg"              
              >
           /
              </div>
            
             
            </li>
          </ul>
        </div>
        <div class="box1-item">
          <div class="biaotix">C-5#聚合釜</div>
          <ul>
            <li class="licontent">
              <div class="li_item"></div>
              <div class="li_iteml">自控率</div>
              <div class="li_iteml">稳定性</div>
              <div class="li_iteml">经济性</div>
              <div class="li_iteml">综合评价</div>
            </li>
            <li class="licontent">
              <div class="li_item">班运行</div>
              <div
                class="li_value "
                @click="
                  toCompon(2, 'ANEV101', 'DEVICE5V', 'ANEV101_FYF', '班总自控率指标')
                "
                @dblclick="
                  Cclick(infoList.DEVICE5V.ANEV101, 'ANEV101', 'DEVICE5V', 'ANEV101_FYF')
                "
              >
             {{ infoList.DEVICE5V.ANEV101 }}
              </div>
              <div
                class="li_value "
                @click="
                  toCompon(2, 'ANEV102', 'DEVICE5V', 'ANEV102_FYF', '班稳定性指标')
                "
                @dblclick="
                  Cclick(infoList.DEVICE5V.ANEV102, 'ANEV102', 'DEVICE5V', 'ANEV102_FYF')
                "
                >
             {{ infoList.DEVICE5V.ANEV102 }}
              </div>
              <div
                class="li_value "
                @click="
                  toCompon(2, 'ANEV103', 'DEVICE5V', 'ANEV103_FYF', '班经济性指标')
                "
                @dblclick="
                  Cclick(infoList.DEVICE5V.ANEV103, 'ANEV103', 'DEVICE5V', 'ANEV103_FYF')
                "
              >
             {{ infoList.DEVICE5V.ANEV103 }}
              </div>
              <div
                class="li_value "
                @click="
                  toCompon(2, 'ANEV104', 'DEVICE5V', 'ANEV104_FYF', '班综合评价指标')
                "
                @dblclick="
                  Cclick(infoList.DEVICE5V.ANEV104, 'ANEV104', 'DEVICE5V', 'ANEV104_FYF')
                "
              >
             {{ infoList.DEVICE5V.ANEV104 }}
              </div>
            </li>
            <li class="licontent">
              <div class="li_item">日运行</div>
              <div
                class="li_value "
                @click="
                  toCompon(2, 'ANEV201', 'DEVICE5V', 'ANEV201_FYF', '日总自控率指标')
                "
                @dblclick="
                  Cclick(infoList.DEVICE5V.ANEV201, 'ANEV201', 'DEVICE5V', 'ANEV201_FYF')
                "
              >
             {{ infoList.DEVICE5V.ANEV201 }}
              </div>
              <div
                class="li_value "
                @click="
                  toCompon(2, 'ANEV202', 'DEVICE5V', 'ANEV202_FYF', '日稳定性指标')
                "
                @dblclick="
                  Cclick(infoList.DEVICE5V.ANEV202, 'ANEV202', 'DEVICE5V', 'ANEV202_FYF')
                "
                >
             {{ infoList.DEVICE5V.ANEV202 }}
              </div>
              <div
                class="li_value "
                @click="
                  toCompon(2, 'ANEV203', 'DEVICE5V', 'ANEV203_FYF', '日经济性指标')
                "
                @dblclick="
                  Cclick(infoList.DEVICE5V.ANEV203, 'ANEV203', 'DEVICE5V', 'ANEV203_FYF')
                "
              >
             {{ infoList.DEVICE5V.ANEV203 }}
              </div>
              <div
                class="li_value "
                @click="
                  toCompon(2, 'ANEV204', 'DEVICE5V', 'ANEV204_FYF', '日综合评价指标')
                "
                @dblclick="
                  Cclick(infoList.DEVICE5V.ANEV204, 'ANEV204', 'DEVICE5V', 'ANEV204_FYF')
                "
              >
             {{ infoList.DEVICE5V.ANEV204 }}
              </div>
            </li>
            <li class="licontent">
              <div class="li_item">月运行</div>
              <div
                class="li_value "
                @click="
                  toCompon(2, 'ANEV301', 'DEVICE5V', 'ANEV301_FYF', '月总自控率指标')
                "
                @dblclick="
                  Cclick(infoList.DEVICE5V.ANEV301, 'ANEV301', 'DEVICE5V', 'ANEV301_FYF')
                "
              >
             {{ infoList.DEVICE5V.ANEV301 }}
              </div>
              <div
                class="li_value "
                @click="
                  toCompon(2, 'ANEV302', 'DEVICE5V', 'ANEV302_FYF', '月稳定性指标')
                "
                @dblclick="
                  Cclick(infoList.DEVICE5V.ANEV302, 'ANEV302', 'DEVICE5V', 'ANEV302_FYF')
                "
                >
             {{ infoList.DEVICE5V.ANEV302 }}
              </div>
              <div
                class="li_value "
                @click="
                  toCompon(2, 'ANEV303', 'DEVICE5V', 'ANEV303_FYF', '月经济性指标')
                "
                @dblclick="
                  Cclick(infoList.DEVICE5V.ANEV303, 'ANEV303', 'DEVICE5V', 'ANEV303_FYF')
                "
              >
             {{ infoList.DEVICE5V.ANEV303 }}
              </div>
              <div
                class="li_value "
                @click="
                  toCompon(2, 'ANEV304', 'DEVICE5V', 'ANEV304_FYF', '月综合评价指标')
                "
                @dblclick="
                  Cclick(infoList.DEVICE5V.ANEV304, 'ANEV304', 'DEVICE5V', 'ANEV304_FYF')
                "
              >
             {{ infoList.DEVICE5V.ANEV304 }}
              </div>
            </li>
            <li class="licontent">
              <div class="li_item">自定义运行</div>
              <div
                class="li_value "
                @click="
                  toCompon(2, 'ANEV401', 'DEVICE5V', 'ANEV401_FYF', '自定义总自控率指标')
                "
                @dblclick="
                  Cclick(infoList.DEVICE5V.ANEV401, 'ANEV401', 'DEVICE5V', 'ANEV401_FYF')
                "
              >
             {{ infoList.DEVICE5V.ANEV401 }}
              </div>
              <div
                class="li_value "
                @click="
                  toCompon(2, 'ANEV402', 'DEVICE5V', 'ANEV402_FYF', '自定义稳定性指标')
                "
                @dblclick="
                  Cclick(infoList.DEVICE5V.ANEV402, 'ANEV402', 'DEVICE5V', 'ANEV402_FYF')
                "
                >
             {{ infoList.DEVICE5V.ANEV402 }}
              </div>
              <div
                class="li_value "
                @click="
                  toCompon(2, 'ANEV403', 'DEVICE5V', 'ANEV403_FYF', '自定义经济性指标')
                "
                @dblclick="
                  Cclick(infoList.DEVICE5V.ANEV403, 'ANEV403', 'DEVICE5V', 'ANEV403_FYF')
                "
              >
             {{ infoList.DEVICE5V.ANEV403 }}
              </div>
              <div
                class="li_value "
                @click="
                  toCompon(2, 'ANEV404', 'DEVICE5V', 'ANEV404_FYF', '自定义综合评价指标')
                "
                @dblclick="
                  Cclick(infoList.DEVICE5V.ANEV404, 'ANEV404', 'DEVICE5V', 'ANEV404_FYF')
                "
              >
             {{ infoList.DEVICE5V.ANEV404 }}
              </div>
            </li>
            <li class="liyxpj">
              <div class="li_item">CEI</div>
              <div
                class="lipjjg"              
              >
             /
              </div>
              <div
                class="lipjjg"              
              >
           /
              </div>
            
             
            </li>
          </ul>
        </div>
        <div class="box1-item">
          <div class="biaotix">C-6#聚合釜</div>
          <ul>
            <li class="licontent">
              <div class="li_item"></div>
              <div class="li_iteml">自控率</div>
              <div class="li_iteml">稳定性</div>
              <div class="li_iteml">经济性</div>
              <div class="li_iteml">综合评价</div>
            </li>
            <li class="licontent">
              <div class="li_item">班运行</div>
              <div
                class="li_value "
                @click="
                  toCompon(2, 'ANEV101', 'DEVICE6V', 'ANEV101_FYF', '班总自控率指标')
                "
                @dblclick="
                  Cclick(infoList.DEVICE6V.ANEV101, 'ANEV101', 'DEVICE6V', 'ANEV101_FYF')
                "
              >
             {{ infoList.DEVICE6V.ANEV101 }}
              </div>
              <div
                class="li_value "
                @click="
                  toCompon(2, 'ANEV102', 'DEVICE6V', 'ANEV102_FYF', '班稳定性指标')
                "
                @dblclick="
                  Cclick(infoList.DEVICE6V.ANEV102, 'ANEV102', 'DEVICE6V', 'ANEV102_FYF')
                "
                >
             {{ infoList.DEVICE6V.ANEV102 }}
              </div>
              <div
                class="li_value "
                @click="
                  toCompon(2, 'ANEV103', 'DEVICE6V', 'ANEV103_FYF', '班经济性指标')
                "
                @dblclick="
                  Cclick(infoList.DEVICE6V.ANEV103, 'ANEV103', 'DEVICE6V', 'ANEV103_FYF')
                "
              >
             {{ infoList.DEVICE6V.ANEV103 }}
              </div>
              <div
                class="li_value "
                @click="
                  toCompon(2, 'ANEV104', 'DEVICE6V', 'ANEV104_FYF', '班综合评价指标')
                "
                @dblclick="
                  Cclick(infoList.DEVICE6V.ANEV104, 'ANEV104', 'DEVICE6V', 'ANEV104_FYF')
                "
              >
             {{ infoList.DEVICE6V.ANEV104 }}
              </div>
            </li>
            <li class="licontent">
              <div class="li_item">日运行</div>
              <div
                class="li_value "
                @click="
                  toCompon(2, 'ANEV201', 'DEVICE6V', 'ANEV201_FYF', '日总自控率指标')
                "
                @dblclick="
                  Cclick(infoList.DEVICE6V.ANEV201, 'ANEV201', 'DEVICE6V', 'ANEV201_FYF')
                "
              >
             {{ infoList.DEVICE6V.ANEV201 }}
              </div>
              <div
                class="li_value "
                @click="
                  toCompon(2, 'ANEV202', 'DEVICE6V', 'ANEV202_FYF', '日稳定性指标')
                "
                @dblclick="
                  Cclick(infoList.DEVICE6V.ANEV202, 'ANEV202', 'DEVICE6V', 'ANEV202_FYF')
                "
                >
             {{ infoList.DEVICE6V.ANEV202 }}
              </div>
              <div
                class="li_value "
                @click="
                  toCompon(2, 'ANEV203', 'DEVICE6V', 'ANEV203_FYF', '日经济性指标')
                "
                @dblclick="
                  Cclick(infoList.DEVICE6V.ANEV203, 'ANEV203', 'DEVICE6V', 'ANEV203_FYF')
                "
              >
             {{ infoList.DEVICE6V.ANEV203 }}
              </div>
              <div
                class="li_value "
                @click="
                  toCompon(2, 'ANEV204', 'DEVICE6V', 'ANEV204_FYF', '日综合评价指标')
                "
                @dblclick="
                  Cclick(infoList.DEVICE6V.ANEV204, 'ANEV204', 'DEVICE6V', 'ANEV204_FYF')
                "
              >
             {{ infoList.DEVICE6V.ANEV204 }}
              </div>
            </li>
            <li class="licontent">
              <div class="li_item">月运行</div>
              <div
                class="li_value "
                @click="
                  toCompon(2, 'ANEV301', 'DEVICE6V', 'ANEV301_FYF', '月总自控率指标')
                "
                @dblclick="
                  Cclick(infoList.DEVICE6V.ANEV301, 'ANEV301', 'DEVICE6V', 'ANEV301_FYF')
                "
              >
             {{ infoList.DEVICE6V.ANEV301 }}
              </div>
              <div
                class="li_value "
                @click="
                  toCompon(2, 'ANEV302', 'DEVICE6V', 'ANEV302_FYF', '月稳定性指标')
                "
                @dblclick="
                  Cclick(infoList.DEVICE6V.ANEV302, 'ANEV302', 'DEVICE6V', 'ANEV302_FYF')
                "
                >
             {{ infoList.DEVICE6V.ANEV302 }}
              </div>
              <div
                class="li_value "
                @click="
                  toCompon(2, 'ANEV303', 'DEVICE6V', 'ANEV303_FYF', '月经济性指标')
                "
                @dblclick="
                  Cclick(infoList.DEVICE6V.ANEV303, 'ANEV303', 'DEVICE6V', 'ANEV303_FYF')
                "
              >
             {{ infoList.DEVICE6V.ANEV303 }}
              </div>
              <div
                class="li_value "
                @click="
                  toCompon(2, 'ANEV304', 'DEVICE6V', 'ANEV304_FYF', '月综合评价指标')
                "
                @dblclick="
                  Cclick(infoList.DEVICE6V.ANEV304, 'ANEV304', 'DEVICE6V', 'ANEV304_FYF')
                "
              >
             {{ infoList.DEVICE6V.ANEV304 }}
              </div>
            </li>
            <li class="licontent">
              <div class="li_item">自定义运行</div>
              <div
                class="li_value "
                @click="
                  toCompon(2, 'ANEV401', 'DEVICE5V', 'ANEV401_FYF', '自定义总自控率指标')
                "
                @dblclick="
                  Cclick(infoList.DEVICE5V.ANEV401, 'ANEV401', 'DEVICE5V', 'ANEV401_FYF')
                "
              >
             {{ infoList.DEVICE5V.ANEV401 }}
              </div>
              <div
                class="li_value "
                @click="
                  toCompon(2, 'ANEV402', 'DEVICE5V', 'ANEV402_FYF', '自定义稳定性指标')
                "
                @dblclick="
                  Cclick(infoList.DEVICE5V.ANEV402, 'ANEV402', 'DEVICE5V', 'ANEV402_FYF')
                "
                >
             {{ infoList.DEVICE5V.ANEV402 }}
              </div>
              <div
                class="li_value "
                @click="
                  toCompon(2, 'ANEV403', 'DEVICE5V', 'ANEV403_FYF', '自定义经济性指标')
                "
                @dblclick="
                  Cclick(infoList.DEVICE5V.ANEV403, 'ANEV403', 'DEVICE5V', 'ANEV403_FYF')
                "
              >
             {{ infoList.DEVICE5V.ANEV403 }}
              </div>
              <div
                class="li_value "
                @click="
                  toCompon(2, 'ANEV404', 'DEVICE5V', 'ANEV404_FYF', '自定义综合评价指标')
                "
                @dblclick="
                  Cclick(infoList.DEVICE5V.ANEV404, 'ANEV404', 'DEVICE5V', 'ANEV404_FYF')
                "
              >
             {{ infoList.DEVICE5V.ANEV404 }}
              </div>
            </li>
            <li class="liyxpj">
              <div class="li_item">CEI</div>
              <div
                class="lipjjg"              
              >
             /
              </div>
              <div
                class="lipjjg"              
              >
           /
              </div>
            
             
            </li>
          </ul>
        </div>
      </div>
       <ul style="display: flex">
          <li class="li_bottom_left">
            <div class="li_bottom_item"> 
             <div class="tupian"></div>
             <div class="valuel">
              <div class="valuel_item1"
              @click="
                  toCompon(2, 'AN48', 'PUB', 'AN48', '总自控率')
                "
                @dblclick="
                  Cclick(infoList.PUB.AN48, 'AN48', 'PUB', 'AN48')
                ">
                 {{ infoList.PUB.AN48 }}
                </div>
              <div class="valuel_item2">总自控率</div>
             </div>
            
            </div>
            <div class="li_bottom_item"> 
             <div class="tupian"></div>
             <div class="valuel">
              <div class="valuel_item1"
              @click="
                  toCompon(2, 'AN49', 'PUB', 'AN49', '总稳定性')
                "
                @dblclick="
                  Cclick(infoList.PUB.AN49, 'AN49', 'PUB', 'AN49')
                ">
                 {{ infoList.PUB.AN49 }}
                 </div>
              <div class="valuel_item2">总稳定性</div>
             </div>           
            </div>
            <div class="li_bottom_item"> 
             <div class="tupian"></div>
             <div class="valuel">
              <div class="valuel_item1" 
               @click="
                  toCompon(2, 'AN50', 'PUB', 'AN50', '总稳定性')
                "
                @dblclick="
                  Cclick(infoList.PUB.AN50, 'AN50', 'PUB', 'AN50')
                ">
                 {{ infoList.PUB.AN50 }}
                 </div>
              <div class="valuel_item2">总经济性</div>
             </div>           
            </div>
          </li>
          <li class="li_bottom_right">
            <div class="tupian2">
              <div class="valuel_item3"></div>
              <div class="valuel_item4">总CEI</div>
            </div>
             <div class="valuelX">
             <!-- AAAAAA -->
             </div>   
          </li>
   
        </ul>
    </div>
  </div>
</template>
<script>
import index from "./_index/index.js";
import Manual from "@/components/Manual.vue"; //手操器组件
//import Evaluate from "@/components/Evaluate.vue"; //运行评价组件
//import Security from "@/components/Security.vue"; //安全设置组件
import Firstcontrol from "@/components/Firstcontrol.vue"; //先控画面组件
import Historical from "@/components/Historical.vue"; //历史趋势
export default {
  name: "Jhindex",
  components: {
    Manual,
    Firstcontrol,
    Historical,
  },
  props: {
    infoList: {
      type: Object,
      default: () => {
        return {}; // 默认值
      },
    },
  },
  data: () => {
    return {
      chName: "",
      maskbok: false,
      JRLcysz: false,
      isMshow: false,
      isHshow: false,
      Aname: "",
      Manualname: "",
      Manualnode: "",
      ManualAname: "",
      Manualtitname: "",
      canshu1: "",
      isComShow: false,
      historyname: "",
      nodename: "",
      flag: 0,
      isComShow: false,
      fullscreenLoading: false,
      grouptime: null,
      spotArr: [],
      projectData: "",
    };
  },
  mixins: [index],
  created() {
    this.spotArr = JSON.parse(localStorage.getItem("spotArr"))
      ? JSON.parse(localStorage.getItem("spotArr"))
      : [];
    this.authInfo = JSON.parse(localStorage.getItem("autharr"));
    this.UserInfo = JSON.parse(localStorage.getItem("UserInfo"));
  },
  watch: {
    infoList: {
      handler(n, o) {
        this.infoList = n;
      },

      deep: true, // 深度监听父组件传过来对象变化
    },
  },
  methods: {
    // 双击事件
    Cclick(num, mark, node, name) {
      // 组装数据
      clearTimeout(this.grouptime);
      let spojobj = {
        num: num,
        mark: mark,
        node: node,
        name: name,
      };
      this.spotArr.push(spojobj);
      // 数组去重
      let deWeightThree = () => {
        let map = new Map();
        for (let item of this.spotArr) {
          if (!map.has(item.name)) {
            this.$message.closeAll();
            map.set(item.name, item);
            this.$message.success("已添加");
          } else {
            this.$message.closeAll();
            this.$message.error("已添加到变量池里，切勿重复添加");
          }
        }

        return [...map.values()];
      };
      this.spotArr = deWeightThree();
      localStorage.setItem("spotArr", JSON.stringify(this.spotArr));
    },
  },
  filters: {
    // 阀门红绿灯
    typeFifter: (val) => {
      if (val < 5) {
        return require("@/assets/images/截止阀.png");
      } else if (val >= 5) {
        return require("@/assets/images/截止阀2.png");
      }
    },
    //釜内红绿灯
    tyFifter: (val) => {
      if (val <= 10) {
        return require("@/assets/images/关闭.png");
      } else if (val > 10) {
        return require("@/assets/images/开始.png");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.jhyxpj {
  // height: 96vh;

  .title {
    width: 100vw;
    text-align: center;
    height: 4vh;
    line-height: 4vh;
    font-family: MicrosoftYaHei;
    font-size: 1.5vw;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 0.5vw;
    color: #ffffff;
    box-shadow: 0vw 0vw 0vw 0vw #009ed2;
  }
  .content {
    // display: flex;

    .box1-item {
      width: 31vw;
      height: 26vh;
      border: 1px solid #486164;
      margin-left: 1.8vw;
      margin-top: 3vh;
    }
    .biaotix {
      width: 31vw;
      height: 2vh;
      line-height: 2vh;
      margin-top: 1vh;
      text-align: center;
      font-family: MicrosoftYaHei-Bold;
      font-size: 1vw;
      font-weight: normal;
      font-stretch: normal;
      line-height: 1vw;
      letter-spacing: 0vw;
      color: #ffffff;
      margin-bottom: 1vh;
    }
    .licontent {
      display: flex;
      color: #ffffff;
      height: 3vh;
      line-height:3vh;
      .li_item {
        width: 6.8vw;
        text-align: center;
        font-size: 0.8vw;
        color: #8aeaff;
        font-weight: normal;
        font-stretch: normal;
        font-family: PingFang-SC-Reg;
        border-top: 1px solid #486164;
        // border-left: 1px solid #486164;
        // border-right: 1px solid #486164;
      }
      .li_iteml {
        width: 6.8vw;
        text-align: center;
        font-size: 0.8vw;
        color: #8aeaff;
        font-weight: normal;
        font-stretch: normal;
        font-family: PingFang-SC-Reg;
        border-top: 1px solid #486164;
        border-left: 1px solid #486164;
        // border-right: 1px solid #486164;
      }
      .li_value {
        width: 6.8vw;
        text-align: center;
        font-size: 0.8vw;
        color: #ffffff;
        font-weight: normal;
        font-stretch: normal;
        font-family: PingFang-SC-Reg;
        border-top: 1px solid #486164;
        border-left: 1px solid #486164;
        // border-rigth: 1px solid #486164;
      }
    }
    .liyxpj{
      display: flex;
      color: #ffffff;
      height: 7vh;
      line-height:7vh;
      .li_item {
        width: 6.25vw;
        height: 7vh;
        text-align: center;
        font-size: 0.8vw;
        color: #8aeaff;
        font-weight: normal;
        font-stretch: normal;
        font-family: PingFang-SC-Reg;
        border-top: 1px solid #486164;
        // border-left: 1px solid #486164;
        // border-right: 1px solid #486164;
      }
      .lipjjg{
        width:12.5vw;
        text-align: center;
        font-size: 0.8vw;
        color: #ffffff;
        font-weight: normal;
        font-stretch: normal;
        font-family: PingFang-SC-Reg;
        border-top: 1px solid #486164;
        border-left: 1px solid #486164;
      }
    }
   
  }
  .li_bottom_left{
    width:64vw;
    height: 17vh;
    margin-left: 1.8vw;
    margin-top: 3vh;
    display: flex;
    border: 1px solid #486164;
    .li_bottom_item{
      width:21.3vw;
      height: 17vh;
      line-height: 17vh;
      display: flex;
      border-right: 1px solid #486164;
     
        .tupian{
          width: 4vw;
          height: 8vh;
          background-image: url("~@/assets/images/瞬时总自控率.png");
        background-size: 100% 100%;
        // border: 1px solid #ffffff;
        margin-top:5vh;
        margin-left:7vw;
        }
        .valuel{
          margin-left: 1vw;
          height: 17vh;
          line-height: 17vh;
          box-sizing: border-box;
          padding-top:4vh;
          .valuel_item1{
            width:5vw;
            line-height:4vh;
            color: #00e4ff;
            font-size: 1.1vw;
          
          }
          .valuel_item2{
            width:5vw;
            line-height: 4vh;
            font-size: 0.8vw;
            color: #8aeaff;
          }

        }
    

      }     
  }
  .li_bottom_right{
    width:31vw;
    height: 17vh;
    margin-left: 1.8vw;
    margin-top: 3vh;
    display: flex;
    border: 1px solid #486164;
    .tupian2{
          width: 4vw;
          height: 8vh;
          background-image: url("~@/assets/images/底座.png");
        background-size: 100% 100%;
        // border: 1px solid #ffffff;
        margin-top:5vh;
        margin-left:10vw;
        box-sizing: border-box;
        padding-left:0.5vw;
        
        }
    .valuel_item3{
      width:5vw;
            line-height:4vh;
            color: #00e4ff;
            font-size: 1.1vw;
    }
    .valuel_item4{
            width:5vw;
            line-height: 4vh;
            font-size: 0.8vw;
            color: #8aeaff;
          }
    .valuelX{
      width:5vw;
      color: #FFFFFF;
      line-height: 17vh;
      margin-left: 1vw;
    }
  }
}
</style>


